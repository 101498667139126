<template>
    <div class="m-image-tag">
        <q-chip class="m-image-tag__field -text">
            {{tagValue}}

            <q-tooltip>
              <b>Name</b>: {{tagName}}
              <br/>
              <b>Confidence</b>: {{tagConfidence}}
              <br/>
              <b>Category</b>: {{tagCategory}}
            </q-tooltip>
        </q-chip>
    </div>
</template>

<script lang="ts">
import { DeepReadonly, defineComponent, PropType, computed } from 'vue';
import { IImageTag } from '@/store/contracts/apiTest/imageAnalysis';

export default defineComponent({
  props: {
    tag: {
      required: true,
      type: Object as PropType<DeepReadonly<IImageTag>>
    }
  },
  setup (props) {
    const tagName = computed(() => props.tag.tagName);
    const tagConfidence = computed(() => `${(props.tag.confidence * 100).toFixed(2)}%`);
    const tagCategory = computed(() => props.tag.category);
    const tagValue = computed(() => `${props.tag.tagName}: ${props.tag.category}`);

    return {
      tagValue,
      tagName,
      tagConfidence,
      tagCategory
    };
  }
});
</script>
