<template>
     <div class="o-imageAnalysis">
        <div class="m-pageHeader">
          <div class="m-pageHeader__title -imageAnalysis text-pl-black text-body2">Analyze</div>
        </div>
        <div class="m-pageContent">
          <div class="o-imageAnalysis__fileupload">
            <div class="o-imageAnalysis__upload__accepts">
                Accepts ({{ displayAcceptStr }}) files. Must be at least 50x50 and less than 4MB.
            </div>
            <q-file
              v-model="files"
              label="Pick Files"
              outlined
              multiple
              use-chips
              append
              :accept="uploadAcceptStr"
              :max-file-size="maxFileSize"
              style="width: 320px"
              @rejected="filesRejected"
              >
              <template v-slot:prepend>
                <q-icon name="mdi-attachment" />
              </template>
              <template v-slot:after v-if="!noFiles">
                <q-btn
                  color="pl-navy-900"
                  dense
                  icon="mdi-cloud-upload"
                  round
                  @click="onUpload"
                  :disable="noFiles"
                />
              </template>
            </q-file>
          </div>
        </div>
        <div v-if="hasResponses">
          <ImageAnalyzeResponse
              v-for="response in responses"
              :key="response.fileKey"
              :responseName="response.fileKey"
              :response="response.res"/>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useStore as useImageAnalyzeStore } from '@/store/apiTest/imageAnalysis';
import { useStore as useUploadStore } from '@/store/apiTest/upload';
import ImageAnalyzeResponse from '@/components/apiTest/ImageAnalyzeResponse.vue';
import { useQuasar } from 'quasar';
import { onBeforeRouteLeave } from 'vue-router';

export default defineComponent({
  components: {
    ImageAnalyzeResponse
  },

  setup () {
    const $q = useQuasar();

    const imageAnalyzeStore = useImageAnalyzeStore();
    const uploadStore = useUploadStore();

    uploadStore.setRoutedNamespace('Vision');
    onBeforeRouteLeave(() => {
      uploadStore.setRoutedNamespace('');
    });

    uploadStore.cleanupSuccess('Vision');

    const responses = computed(() => Object.keys(imageAnalyzeStore.state.responses).map((fileKey) => ({ fileKey, res: imageAnalyzeStore.state.responses[fileKey]! })));
    const hasResponses = computed(() => responses.value.length > 0);

    const uploadAcceptStr = computed(() => imageAnalyzeStore.getAcceptedMimes().join(', '));
    const displayAcceptStr = computed(() => imageAnalyzeStore.getAcceptedExtensions().join(', '));

    const onUpload = async () => {
      const uploadStarted = await imageAnalyzeStore.uploadFiles(uploadStore);
      if (uploadStarted) {
        imageAnalyzeStore.setFiles(undefined);
      }
    };

    const files = computed({
      get: () => imageAnalyzeStore.state.files,
      set: val => imageAnalyzeStore.setFiles(val as FileList)
    });

    const noFiles = computed(() => imageAnalyzeStore.noFiles);

    const filesRejected = (rejectedEntries) => {
      $q.notify({
        type: 'negative',
        message: `${rejectedEntries.length} file(s) were not accepted (invalid file type or file too large).`
      });
    };

    // Max size of 4 MB.
    const maxFileSize = 4 * 1024 * 1024;

    return {
      responses,
      hasResponses,
      uploadAcceptStr,
      displayAcceptStr,
      files,
      noFiles,
      onUpload,
      filesRejected,
      maxFileSize
    };
  }
});
</script>

<style lang="scss">
.o-imageAnalysis {
    margin: 20px;

  .m-pageHeader {
      justify-content: space-between;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  .m-analyze-response {
    margin-top: 16px;
    margin-left: 16px;
  }
}
</style>
