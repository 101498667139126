import { render } from "./RequestItem.vue?vue&type=template&id=170410b9"
import script from "./RequestItem.vue?vue&type=script&lang=ts"
export * from "./RequestItem.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QItem,QItemSection,QAvatar,QSpinner,QItemLabel,QTooltip,QBtn});
