<template>
  <q-page-container>
    <slot name="before"/>
    <router-view />
    <slot name="after"/>
  </q-page-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
