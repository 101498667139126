<template>
    <q-expansion-item
        class="m-analyze-response"
        :header-class="headerClass"
        expand-icon-class="text-pl-white"
        expand-separator
        switch-toggle-side>
        <template v-slot:header>
          <q-item-section>
            {{ responseName }}
          </q-item-section>
          <q-item-section side>
            <q-btn
              flat
              icon="mdi-close"
              color="white"
              @click="removeResponse"
              >
              <q-tooltip>
                Remove Image Analysis
              </q-tooltip>
            </q-btn>
          </q-item-section>
        </template>
        <div v-if="hasResponse">
            <div class="row">
              <ImageTag v-for="tag in tags" :key="createTagKey(tag)" :tag="tag"/>
            </div>
        </div>
        <div v-else>
            Image Analysis Not Successful
        </div>
    </q-expansion-item>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, DeepReadonly } from 'vue';
import { IImageResult, IImageTag } from '@/store/contracts/apiTest/imageAnalysis';
import ImageTag from '@/components/apiTest/ImageTag.vue';
import { useStore as useImageAnalyzeStore } from '@/store/apiTest/imageAnalysis';

export default defineComponent({
  components: {
    ImageTag
  },
  props: {
    responseName: {
      required: true,
      type: String
    },
    response: {
      required: true,
      type: Object as PropType<DeepReadonly<IImageResult>>
    }
  },
  setup (props) {
    const imageAnayzeStore = useImageAnalyzeStore();

    const hasResponse = !!props.response;

    const headerClass = computed(() => ({
      'bg-pl-red-100': !hasResponse,
      'bg-green-7': hasResponse,
      'text-pl-white': true
    }));

    const tags = computed(() => props.response.tags);

    function createTagKey (tag: IImageTag) : string {
      return `${tag.tagName}`;
    }

    const removeResponse = () => {
      imageAnayzeStore.removeResponse(props.responseName);
    };
    return {
      hasResponse,
      headerClass,
      tags,
      createTagKey,
      removeResponse
    };
  }
});
</script>

<style lang="scss">
.m-analyze-response {
    .q-expansion-item__content {
        padding-top: 8px;
        margin-left: 16px;
        padding-bottom: 8px;
    }

    &__field {
        margin-top: 8px;
    }
}
</style>
