
import { defineComponent, computed, PropType, DeepReadonly } from 'vue';
import { IImageResult, IImageTag } from '@/store/contracts/apiTest/imageAnalysis';
import ImageTag from '@/components/apiTest/ImageTag.vue';
import { useStore as useImageAnalyzeStore } from '@/store/apiTest/imageAnalysis';

export default defineComponent({
  components: {
    ImageTag
  },
  props: {
    responseName: {
      required: true,
      type: String
    },
    response: {
      required: true,
      type: Object as PropType<DeepReadonly<IImageResult>>
    }
  },
  setup (props) {
    const imageAnayzeStore = useImageAnalyzeStore();

    const hasResponse = !!props.response;

    const headerClass = computed(() => ({
      'bg-pl-red-100': !hasResponse,
      'bg-green-7': hasResponse,
      'text-pl-white': true
    }));

    const tags = computed(() => props.response.tags);

    function createTagKey (tag: IImageTag) : string {
      return `${tag.tagName}`;
    }

    const removeResponse = () => {
      imageAnayzeStore.removeResponse(props.responseName);
    };
    return {
      hasResponse,
      headerClass,
      tags,
      createTagKey,
      removeResponse
    };
  }
});
