
import { defineComponent, computed } from 'vue';
import { useStore as useUploadStore } from '@/store/apiTest/upload';
import RequestItem from '@/components/apiTest/RequestItem.vue';

export default defineComponent({
  components: {
    RequestItem
  },
  setup () {
    const uploadStore = useUploadStore();
    const uploads = computed(() => {
      const keys = Object.keys(uploadStore.state.uploads);
      return keys.map(k => {
        return {
          key: k,
          values: uploadStore.state.uploads[k]
        };
      }).filter(u => u.values.length !== 0);
    });
    const noUploads = computed(() => uploadStore.noUploads);
    const isOpen = computed({
      get: () => uploadStore.state.isOpen,
      set: (value) => uploadStore.setIsOpen(value)
    });
    const notify = computed(() => uploadStore.state.notify);
    return {
      uploads,
      noUploads,
      isOpen,
      notify
    };
  }
});
