
import { defineComponent, computed, DeepReadonly, PropType } from 'vue';
import { useStore as useUploadStore } from '@/store/apiTest/upload';
import { IUploadInformation, UploadState } from '@/store/contracts/apiTest/upload';
import { isNullOrWhitespace } from '@/utils/stringUtils';

export default defineComponent({
  props: {
    upload: {
      required: true,
      type: Object as PropType<DeepReadonly<IUploadInformation>>
    },
    namespace: {
      required: true,
      type: String
    }
  },
  setup (props) {
    const uploadStore = useUploadStore();
    const fileName = computed(() => props.upload.fileName);
    const fileStatusDisplay = computed(() => isNullOrWhitespace(props.upload.statusDisplay) ? '' : `${props.upload.statusDisplay} - `);
    const uploadState = computed(() => props.upload.uploadState);
    const isLoading = computed(() => uploadState.value === UploadState.loading);
    const iconName = computed(() => {
      switch (uploadState.value) {
        case UploadState.success:
          return 'mdi-checkbox-marked-circle-outline';
        case UploadState.failed:
          return 'mdi-alert-octagon';
        default:
          return '';
      }
    });
    const iconColor = computed(() => {
      switch (uploadState.value) {
        case UploadState.success:
          return 'green-7';
        case UploadState.failed:
          return 'pl-red-100';
        default:
          return '';
      }
    });
    const abortUpload = () => {
      uploadStore.abortUpload(props.namespace, props.upload as IUploadInformation);
    };
    return {
      fileName,
      uploadState,
      iconName,
      isLoading,
      abortUpload,
      iconColor,
      fileStatusDisplay
    };
  }
});
