<template>
   <div class="o-RequestPanel">
    <q-expansion-item
      class="o-RequestPanel__ExpansionItem"
      v-model="isOpen"
      header-class="bg-pl-navy-900 text-white"
      expand-icon-class="text-white">
        <template v-slot:header>
            <q-item-section avatar>
                <q-icon name="mdi-view-list">
                  <q-badge v-if="notify" color="red" rounded floating label="!" />
                </q-icon>
            </q-item-section>
            <q-item-section>
                Request Status
            </q-item-section>
        </template>
        <q-card bordered>
          <q-card-section v-if="noUploads">
            No requests to display.
          </q-card-section>
          <template v-else>
            <q-card-section v-for="uploadGroup in uploads" v-bind:key="uploadGroup">
              <div class="row">
                <span><b>{{ uploadGroup.key }}</b></span>
              </div>
              <hr>
              <q-list>
              <RequestItem
                v-for="upload in uploadGroup.values"
                v-bind:key="upload.id"
                :upload="upload"
                :namespace="uploadGroup.key" />
              </q-list>
            </q-card-section>
          </template>
        </q-card>
    </q-expansion-item>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore as useUploadStore } from '@/store/apiTest/upload';
import RequestItem from '@/components/apiTest/RequestItem.vue';

export default defineComponent({
  components: {
    RequestItem
  },
  setup () {
    const uploadStore = useUploadStore();
    const uploads = computed(() => {
      const keys = Object.keys(uploadStore.state.uploads);
      return keys.map(k => {
        return {
          key: k,
          values: uploadStore.state.uploads[k]
        };
      }).filter(u => u.values.length !== 0);
    });
    const noUploads = computed(() => uploadStore.noUploads);
    const isOpen = computed({
      get: () => uploadStore.state.isOpen,
      set: (value) => uploadStore.setIsOpen(value)
    });
    const notify = computed(() => uploadStore.state.notify);
    return {
      uploads,
      noUploads,
      isOpen,
      notify
    };
  }
});
</script>

<style lang="scss">
.o-RequestPanel {
    &__ExpansionItem {
        position: fixed;
        bottom: 0px;
        right: 0px;
        width: 400px;
    }
}
</style>
