import { render } from "./ImageAnalyzeResponse.vue?vue&type=template&id=332e8ed0"
import script from "./ImageAnalyzeResponse.vue?vue&type=script&lang=ts"
export * from "./ImageAnalyzeResponse.vue?vue&type=script&lang=ts"

import "./ImageAnalyzeResponse.vue?vue&type=style&index=0&id=332e8ed0&lang=scss"
script.render = render

export default script
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QExpansionItem,QItemSection,QBtn,QTooltip});
