import { provide, inject, reactive, App, readonly } from 'vue';
import {
  IImageAnalyzeState,
  IImageAnalyzeStore,
  IImageResult,
  TSetResponses,
  TGetAcceptedExtensions,
  TGetAcceptedMimes,
  TSetFiles,
  TUploadFiles,
  TReset,
  TRemoveResponse,
  IServerImageResult,
  IServerImageTag,
  IImageTag
} from '@/store/contracts/apiTest/imageAnalysis';
import { IUploadStore, TUploadCompleteCallback } from '@/store/contracts/apiTest/upload';
import { impl } from '@/utils/impl';
import mime from 'mime-types';
import { Settings } from '@/settings';
import validationModel from '@/validation/uploadStateValidationModel';
import { store as validationStore } from '@/store/validation';

const ImageAnalyzeStoreKey = Symbol('ImageAnalyzeStore');

export function fromServerResult (serverModel: IServerImageResult): IImageResult {
  return {
    tags: serverModel.Tags.map(fromServerTag)
  };
}

export function fromServerTag (serverModel: IServerImageTag): IImageTag {
  return {
    category: serverModel.Category,
    confidence: serverModel.Confidence,
    tagName: serverModel.TagName
  };
}

const createState = () => {
  validationStore.clear(validationModel);
  return reactive(impl<IImageAnalyzeState>({
    responses: {},
    files: undefined
  }));
};

function setResponses (state: IImageAnalyzeState): TSetResponses {
  return (responses) => {
    state.responses = responses;
  };
}

function setFiles (state: IImageAnalyzeState): TSetFiles {
  return (files) => {
    state.files = files;
  };
}

function uploadFiles (state: IImageAnalyzeState): TUploadFiles {
  return async (uploadStore: IUploadStore) => {
    if (!state.files || state.files.length === 0) {
      return false;
    }
    return await uploadStore.upload('Vision', `${Settings.clientApiBaseUrl}/v1/vision/analyze`, Array.from(state.files), onUploadSuccess(state));
  };
}

function getFileKey (fileName: string, fileKeys: string[], iter?: number): string {
  const fileKey = iter === undefined ? fileName : `${fileName} (${iter})`;

  if (!fileKeys.includes(fileKey)) {
    return fileKey;
  }

  return getFileKey(fileName, fileKeys, iter === undefined ? 1 : iter + 1);
}

function onUploadSuccess (state: IImageAnalyzeState): TUploadCompleteCallback {
  return (info) => {
    info.forEach(i => {
      const fileKey = getFileKey(i.fileName, Object.keys(state.responses));
      const mappedResponse: IServerImageResult = i.xhr?.data;
      setResponses(state)({ ...state.responses, [fileKey]: fromServerResult(mappedResponse) });
    });
    return Promise.resolve();
  };
}

const acceptedFileExtensions = [
  '.jpeg', '.jpg', '.png', '.gif', '.bmp'
];

const acceptedFileTypes = acceptedFileExtensions.map((e) => mime.lookup(e)).filter((m) => m !== false).map((m) => m as string);

const getAcceptedMimes: TGetAcceptedMimes = () => acceptedFileTypes;

const getAcceptedExtensions: TGetAcceptedExtensions = () => acceptedFileExtensions;

function removeResponse (state: IImageAnalyzeState): TRemoveResponse {
  return (responseKey: string) => {
    delete state.responses[`${responseKey}`];
  };
}

function reset (state: IImageAnalyzeState): TReset {
  return () => {
    setResponses(state)({});
    setFiles(state)(undefined);
  };
}

function createForState (state: IImageAnalyzeState): IImageAnalyzeStore {
  return {
    state: readonly(state),
    setResponses: setResponses(state),
    setFiles: setFiles(state),
    getAcceptedMimes,
    getAcceptedExtensions,
    uploadFiles: uploadFiles(state),
    removeResponse: removeResponse(state),
    reset: reset(state),
    get noFiles (): boolean {
      return !state || !state.files || state.files.length === 0;
    }
  };
}

export function provideStore (app?: App<Element>): void {
  const state = createState();
  if (app !== undefined) {
    app.provide(ImageAnalyzeStoreKey, state);
  } else {
    provide(ImageAnalyzeStoreKey, state);
  }
}

export function useStore (): IImageAnalyzeStore {
  const state = inject<IImageAnalyzeState>(ImageAnalyzeStoreKey);
  if (state === undefined) {
    throw new Error('Using image analyze store before providing it!');
  }
  return createForState(state);
}
