import { render } from "./ImageTag.vue?vue&type=template&id=a33af8b4"
import script from "./ImageTag.vue?vue&type=script&lang=ts"
export * from "./ImageTag.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QChip from 'quasar/src/components/chip/QChip.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QChip,QTooltip});
