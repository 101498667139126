
import { computed, defineComponent } from 'vue';
import { useStore as useImageAnalyzeStore } from '@/store/apiTest/imageAnalysis';
import { useStore as useUploadStore } from '@/store/apiTest/upload';
import ImageAnalyzeResponse from '@/components/apiTest/ImageAnalyzeResponse.vue';
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { onBeforeRouteLeave } from 'vue-router';

export default defineComponent({
  components: {
    ImageAnalyzeResponse
  },

  setup () {
    const $q = useQuasar();

    const imageAnalyzeStore = useImageAnalyzeStore();
    const uploadStore = useUploadStore();

    uploadStore.setRoutedNamespace('Vision');
    onBeforeRouteLeave(() => {
      uploadStore.setRoutedNamespace('');
    });

    uploadStore.cleanupSuccess('Vision');

    const responses = computed(() => Object.keys(imageAnalyzeStore.state.responses).map((fileKey) => ({ fileKey, res: imageAnalyzeStore.state.responses[fileKey]! })));
    const hasResponses = computed(() => responses.value.length > 0);

    const uploadAcceptStr = computed(() => imageAnalyzeStore.getAcceptedMimes().join(', '));
    const displayAcceptStr = computed(() => imageAnalyzeStore.getAcceptedExtensions().join(', '));

    const onUpload = async () => {
      const uploadStarted = await imageAnalyzeStore.uploadFiles(uploadStore);
      if (uploadStarted) {
        imageAnalyzeStore.setFiles(undefined);
      }
    };

    const files = computed({
      get: () => imageAnalyzeStore.state.files,
      set: val => imageAnalyzeStore.setFiles(val as FileList)
    });

    const noFiles = computed(() => imageAnalyzeStore.noFiles);

    const filesRejected = (rejectedEntries) => {
      $q.notify({
        type: 'negative',
        message: `${rejectedEntries.length} file(s) were not accepted (invalid file type or file too large).`
      });
    };

    // Max size of 4 MB.
    const maxFileSize = 4 * 1024 * 1024;

    return {
      responses,
      hasResponses,
      uploadAcceptStr,
      displayAcceptStr,
      files,
      noFiles,
      onUpload,
      filesRejected,
      maxFileSize
    };
  }
});
