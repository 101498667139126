import { render } from "./ImageAnalysis.vue?vue&type=template&id=118208b1"
import script from "./ImageAnalysis.vue?vue&type=script&lang=ts"
export * from "./ImageAnalysis.vue?vue&type=script&lang=ts"

import "./ImageAnalysis.vue?vue&type=style&index=0&id=118208b1&lang=scss"
script.render = render

export default script
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QFile,QIcon,QBtn});
