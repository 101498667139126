<template>
    <q-item class="m-RequestItem">
      <q-item-section avatar top v-if="!isLoading">
        <q-avatar :icon="iconName" :color="iconColor" text-color="white"/>
      </q-item-section>
      <q-item-section avatar top v-else>
        <q-spinner color="primary" size="20px" style="margin-top:8px"/>
      </q-item-section>

      <q-item-section>
        <q-item-label lines="1">
          {{ fileStatusDisplay }}{{ fileName }}
          <q-tooltip>
            {{ fileName }}
          </q-tooltip>
        </q-item-label>
      </q-item-section>
      <q-item-section side>
        <q-btn
        round
        size="xs"
        color="grey"
        icon="mdi-close"
        @click="abortUpload" />
      </q-item-section>
    </q-item>
</template>

<script lang="ts">
import { defineComponent, computed, DeepReadonly, PropType } from 'vue';
import { useStore as useUploadStore } from '@/store/apiTest/upload';
import { IUploadInformation, UploadState } from '@/store/contracts/apiTest/upload';
import { isNullOrWhitespace } from '@/utils/stringUtils';

export default defineComponent({
  props: {
    upload: {
      required: true,
      type: Object as PropType<DeepReadonly<IUploadInformation>>
    },
    namespace: {
      required: true,
      type: String
    }
  },
  setup (props) {
    const uploadStore = useUploadStore();
    const fileName = computed(() => props.upload.fileName);
    const fileStatusDisplay = computed(() => isNullOrWhitespace(props.upload.statusDisplay) ? '' : `${props.upload.statusDisplay} - `);
    const uploadState = computed(() => props.upload.uploadState);
    const isLoading = computed(() => uploadState.value === UploadState.loading);
    const iconName = computed(() => {
      switch (uploadState.value) {
        case UploadState.success:
          return 'mdi-checkbox-marked-circle-outline';
        case UploadState.failed:
          return 'mdi-alert-octagon';
        default:
          return '';
      }
    });
    const iconColor = computed(() => {
      switch (uploadState.value) {
        case UploadState.success:
          return 'green-7';
        case UploadState.failed:
          return 'pl-red-100';
        default:
          return '';
      }
    });
    const abortUpload = () => {
      uploadStore.abortUpload(props.namespace, props.upload as IUploadInformation);
    };
    return {
      fileName,
      uploadState,
      iconName,
      isLoading,
      abortUpload,
      iconColor,
      fileStatusDisplay
    };
  }
});
</script>

<style lang="scss">

</style>
