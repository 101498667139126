<template>
  <q-layout id="apiTest-view bg-pl-gray-50" view="lhh lpR lfr">
    <TopNav id="top-nav"></TopNav>
    <LeftDrawer id="side-nav"></LeftDrawer>
    <Content id="view-content">
      <template v-slot:before>
        <q-input
          class="a-subscriptionKeyInput"
          v-model="subKey"
          label="Subscription Key"
          type="text"
          dense
          :error="subKeyIsError"
          :error-message="subKeyError"
        >
          <template v-slot:append>
            <q-btn
              class="a-subscriptionKeyInput__clear"
              icon="mdi-close"
              round
              flat
              dense
              @click="clearSubKey"
            />
          </template>
        </q-input>
      </template>
    </Content>
  </q-layout>
  <div>
    <RequestPanel />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import Content from '@/components/Content.vue';
import TopNav from '@/components/TopNav.vue';
import LeftDrawer from '@/components/LeftDrawer.vue';
import RequestPanel from '@/components/apiTest/RequestPanel.vue';
import { useStore as useUploadStore } from '@/store/apiTest/upload';
import { provideStore as provideImageAnalysisStore } from '@/store/apiTest/imageAnalysis';
import { useStore as useValidationStore } from '@/store/validation';
import validationModel, { UploadStateFieldName } from '@/validation/uploadStateValidationModel';

export default defineComponent({
  name: 'Home',
  components: {
    Content,
    TopNav,
    LeftDrawer,
    RequestPanel
  },
  setup () {
    const validationStore = useValidationStore();
    provideImageAnalysisStore();
    const uploadStore = useUploadStore();

    const subKey = computed({
      get: () => uploadStore.state.subscriptionKey,
      set: (val) => uploadStore.setSubscriptionKey(val)
    });

    const subKeyIsError = computed(() => validationStore.fieldIsError(validationModel.modelName, UploadStateFieldName.SubscriptionKey));
    const subKeyError = computed(() => validationStore.fieldErrorMessage(validationModel.modelName, UploadStateFieldName.SubscriptionKey));

    function clearSubKey () {
      subKey.value = '';
    }

    return {
      subKey,
      subKeyIsError,
      subKeyError,
      clearSubKey
    };
  }
});
</script>

<style lang="scss">

  .a-subscriptionKeyInput {
    margin: 20px;
    max-width: 500px;
  }

</style>
