import { render } from "./RequestPanel.vue?vue&type=template&id=336637c0"
import script from "./RequestPanel.vue?vue&type=script&lang=ts"
export * from "./RequestPanel.vue?vue&type=script&lang=ts"

import "./RequestPanel.vue?vue&type=style&index=0&id=336637c0&lang=scss"
script.render = render

export default script
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QList from 'quasar/src/components/item/QList.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QExpansionItem,QItemSection,QIcon,QBadge,QCard,QCardSection,QList});
